define("ember-toggle/components/x-toggle-switch", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "@ember/runloop", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _object, _component2, _runloop, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span
    class="x-toggle-container
      {{@size}}
      {{if @disabled 'x-toggle-container-disabled'}}
      {{if @value 'x-toggle-container-checked'}}
    "
    ...attributes
    {{did-pan
      onPanStart=this.handlePan
      onPan=this.handlePan
      pointerTypes=(array 'touch' 'mouse')
    }}
    {{will-destroy this.removeListener}}
  >
    <input
      class='x-toggle'
      type='checkbox'
      checked={{@toggled}}
      disabled={{@disabled}}
      id={{@forId}}
      name={{@name}}
      {{on 'change' this.onChange}}
    />
  
    <label for={{this.effectiveForId}}>
      <div
        id="x-toggle-visual-{{@forId}}"
        role='checkbox'
        class="x-toggle-btn
          {{this.themeClass}}
          {{@variant}}
          {{@size}}
          {{if @disabled ' x-toggle-disabled'}}
        "
        aria-owns={{@forId}}
        aria-checked={{@toggled}}
        data-tg-on={{@onLabel}}
        data-tg-off={{@offLabel}}
      >
      </div>
    </label>
  </span>
  */
  {
    "id": "78IpSZzX",
    "block": "[[[11,1],[16,0,[29,[\"x-toggle-container\\n    \",[30,1],\"\\n    \",[52,[30,2],\"x-toggle-container-disabled\"],\"\\n    \",[52,[30,3],\"x-toggle-container-checked\"],\"\\n  \"]]],[17,4],[4,[38,1],null,[[\"onPanStart\",\"onPan\",\"pointerTypes\"],[[30,0,[\"handlePan\"]],[30,0,[\"handlePan\"]],[28,[37,2],[\"touch\",\"mouse\"],null]]]],[4,[38,3],[[30,0,[\"removeListener\"]]],null],[12],[1,\"\\n  \"],[11,\"input\"],[24,0,\"x-toggle\"],[16,\"checked\",[30,5]],[16,\"disabled\",[30,2]],[16,1,[30,6]],[16,3,[30,7]],[24,4,\"checkbox\"],[4,[38,4],[\"change\",[30,0,[\"onChange\"]]],null],[12],[13],[1,\"\\n\\n  \"],[10,\"label\"],[15,\"for\",[30,0,[\"effectiveForId\"]]],[12],[1,\"\\n    \"],[10,0],[15,1,[29,[\"x-toggle-visual-\",[30,6]]]],[14,\"role\",\"checkbox\"],[15,0,[29,[\"x-toggle-btn\\n        \",[30,0,[\"themeClass\"]],\"\\n        \",[30,8],\"\\n        \",[30,1],\"\\n        \",[52,[30,2],\" x-toggle-disabled\"],\"\\n      \"]]],[15,\"aria-owns\",[30,6]],[15,\"aria-checked\",[30,5]],[15,\"data-tg-on\",[30,9]],[15,\"data-tg-off\",[30,10]],[12],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@size\",\"@disabled\",\"@value\",\"&attrs\",\"@toggled\",\"@forId\",\"@name\",\"@variant\",\"@onLabel\",\"@offLabel\"],false,[\"if\",\"did-pan\",\"array\",\"will-destroy\",\"on\"]]",
    "moduleName": "ember-toggle/components/x-toggle-switch.hbs",
    "isStrictMode": false
  });
  let XToggleSwitch = (_class = class XToggleSwitch extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "labelDisabled", _descriptor, this);
    }
    get effectiveForId() {
      return this.labelDisabled ? null : this.args.forId;
    }
    get themeClass() {
      let theme = this.args.theme || 'default';
      return `x-toggle-${theme}`;
    }
    handlePan(touchData) {
      if (this.args.disabled) {
        return;
      }
      const isToggled = touchData.current.distanceX > 0;
      this.args.sendToggle(isToggled);
      this._disableLabelUntilMouseUp();
    }
    onChange(e) {
      if (this.args.disabled) {
        return;
      }
      this.args.sendToggle(e.target.checked);
    }
    removeListener() {
      this._removeListener();
    }

    /*
      When you pan with a mouse and release the mouse button over the <label>
      element, a click event happens and returns the toggle to its initial
      state. :(
       To prevent this, we need to make the label non-functional until after the
      mouse button is released.
     */
    _disableLabelUntilMouseUp() {
      if (this.labelDisabled) {
        return;
      }
      const _listener = () => {
        (0, _runloop.next)(() => {
          if (this.isDestroying || this.isDestroyed) {
            return;
          }
          this._removeListener();
          this.labelDisabled = false;
        });
      };
      this.labelDisabled = true;
      this._listener = _listener;
      document.addEventListener('mouseup', _listener);
    }
    _removeListener() {
      const _listener = this._listener;
      if (_listener) {
        document.removeEventListener('mouseup', _listener);
        this._listener = null;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "labelDisabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handlePan", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handlePan"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeListener", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeListener"), _class.prototype)), _class);
  _exports.default = XToggleSwitch;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, XToggleSwitch);
});